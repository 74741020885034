import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { firstValueFrom, Subscription } from 'rxjs';

import { ButtonComponent } from '../../../../../../../components/button/button.component';
import { IconComponent } from '../../../../../../../components/icon/icon.component';
import { InputComponent } from '../../../../../../../components/input/input.component';
import { LoadingComponent } from '../../../../../../../components/loading/loading.component';
import { ModalLayoutComponent } from '../../../../../../../components/modal-layout/modal-layout.component';
import { OptionActions } from '../../../../../../../store/actions/option.actions';
import { SampleActions } from '../../../../../../../store/actions/sample.actions';
import {
  selectAllSampleSuites,
  selectSampleMatrices,
  selectSampleTypes,
} from '../../../../../../../store/selectors/option.selector';
import {
  selectNextSampleIdByJobId,
  selectSampleState,
} from '../../../../../../../store/selectors/sample.selector';
import { selectUserProfile } from '../../../../../../../store/selectors/user.selector';

@Component({
  selector: 'app-create-sample-modal',
  standalone: true,
  templateUrl: './create-sample.component.html',
  imports: [
    LoadingComponent,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    IconComponent,
    InputComponent,
    ButtonComponent,
    ModalLayoutComponent,
    NgSelectComponent,
  ],
})
export class CreateSampleModalComponent implements OnInit, OnDestroy {
  @Input()
  public jobId = '';

  @Output()
  public cancelled: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public closed: EventEmitter<void> = new EventEmitter<void>();

  public view: 'initial' | 'response' = 'initial';

  public sampleTypes$ = this.store.pipe(select(selectSampleTypes));
  public matrixTypes$ = this.store.pipe(select(selectSampleMatrices));
  public matrixTypes: { id: string; name: string }[] = [];
  public matrixTypesSub!: Subscription;
  public testSuites$ = this.store.pipe(select(selectAllSampleSuites));
  public testSuites: { id: string; name: string }[] = [];
  public testSuitesSub!: Subscription;

  public loading = false;
  public error: unknown = null;

  public readonly form = new FormGroup({
    sampleDescription: new FormControl('', [Validators.required]),
    type: new FormControl(null, [Validators.required]),
    matrix: new FormControl(null, [Validators.required]),
    suiteCode: new FormControl(null, [Validators.required]),
    notes: new FormControl(''),
  });

  private readonly sampleSub = this.store
    .pipe(select(selectSampleState))
    .subscribe(({ error, pending }) => {
      this.error = error;
      this.loading = pending;
    });

  private _show = false;

  public get show(): boolean {
    return this._show;
  }

  @Input()
  public set show(value: boolean) {
    this._show = value;

    if (value) {
      this.store.dispatch(OptionActions.loadSampleTypes());
      this.store.dispatch(OptionActions.loadSampleMatrices());
      this.store.dispatch(OptionActions.loadSampleSuites());
    }
  }

  public constructor(private readonly store: Store) {}

  public async ngOnInit(): Promise<void> {
    this.testSuitesSub = this.testSuites$.subscribe((params) => {
      this.testSuites = [];
      this.testSuites = params.map(({ code, description }) => ({
        id: code,
        name: description,
      }));
    });

    this.matrixTypesSub = this.matrixTypes$.subscribe((params) => {
      this.matrixTypes = [];
      this.matrixTypes = params.map(({ id, description }) => ({
        id,
        name: description,
      }));
    });
  }

  public ngOnDestroy(): void {
    this.testSuitesSub?.unsubscribe();
    this.matrixTypesSub?.unsubscribe();
    this.sampleSub?.unsubscribe();
  }

  public async createSample(event: SubmitEvent): Promise<void> {
    event.preventDefault();

    const profile = await firstValueFrom(
      this.store.pipe(select(selectUserProfile)),
    );

    const nextId = await firstValueFrom(
      this.store.pipe(select(selectNextSampleIdByJobId(this.jobId))),
    );

    if (!this.form.valid || !profile?.id) {
      return;
    }

    const createdBy = profile?.id;
    const { sampleDescription, type, matrix, suiteCode, notes } =
      this.form.value;

    if (!sampleDescription || !type || !matrix || !suiteCode) {
      return;
    }

    this.store.dispatch(
      SampleActions.createSample({
        jobId: this.jobId,
        sample: {
          id: `${nextId}`,
          sampleDescription,
          sampledById: createdBy,
          type,
          matrix,
          suiteCode,
          notes: notes ?? undefined,
        },
      }),
    );

    this.view = 'response';
  }

  public cancel(): void {
    this.cancelled.emit();
    this.resetState();
  }

  public close(): void {
    this.closed.emit();
    this.resetState();
  }

  private resetState(): void {
    this.view = 'initial';
    this.form.reset();
    this.loading = false;
    this.error = null;
  }
}
