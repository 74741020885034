import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { select, Store } from '@ngrx/store';

import { ButtonComponent } from '../../../../../../components/button/button.component';
import { IconComponent } from '../../../../../../components/icon/icon.component';
import { LoadingComponent } from '../../../../../../components/loading/loading.component';
import { ModalLayoutComponent } from '../../../../../../components/modal-layout/modal-layout.component';
import { JobActions } from '../../../../../../store/actions/job.actions';
import { selectJobState } from '../../../../../../store/selectors/job.selector';

@Component({
  selector: 'app-delete-job-modal',
  standalone: true,
  templateUrl: './delete-job.component.html',
  imports: [
    LoadingComponent,
    CommonModule,
    ButtonComponent,
    IconComponent,
    ModalLayoutComponent,
  ],
})
export class DeleteJobModalComponent implements OnDestroy {
  @Input()
  public show = false;

  @Input({ required: true })
  public jobId = '';

  @Output()
  public cancelled: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public closed: EventEmitter<void> = new EventEmitter<void>();

  public view: 'initial' | 'response' = 'initial';

  public loading = false;
  public error: unknown = null;

  private readonly jobSub = this.store
    .pipe(select(selectJobState))
    .subscribe(({ error, pending }) => {
      this.error = error;
      this.loading = pending;
    });

  public constructor(private readonly store: Store) {}

  public ngOnDestroy(): void {
    this.jobSub?.unsubscribe();
  }

  public async confirm(): Promise<void> {
    this.store.dispatch(JobActions.delete({ id: this.jobId }));
    this.view = 'response';
  }

  public close(): void {
    this.closed.emit();
    this.view = 'initial';
  }
}
